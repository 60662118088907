import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  Typography,
  Box,
  Divider,
  IconButton,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
} from '@material-ui/core';
import { Link } from 'gatsby';
import { MoreVertOutlined } from '@material-ui/icons';

const SectionContainerLayoutWithDate = ({
  title,
  filters = [],
  linkUrl,
  viewAll = false,
  showDivider = false,
  callback = false,
  handleItem,
  children,
  viewAllAlternative,
  simpleFilters = false,
  defaultPage = false,
  filterBold = '',
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box mt={5}>
      <Grid container spacing={3}>
        <Grid item container xs={12}>
          <Box clone flexGrow={1}>
            <Grid item>
              <Typography variant='h4' color='primary' gutterBottom>
                <Box fontWeight='fontWeightBold'>{title}</Box>
              </Typography>
            </Grid>
          </Box>
          {viewAll && linkUrl && (
            <Box display={{ xs: 'none', sm: 'block' }} clone py={{ md: 2, sm: 1 }} px={1}>
              <Grid item>
                <Link
                  className='MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary text-decoration-none'
                  to={`${linkUrl}`}>
                  <Box clone>
                    <Typography variant='button' display='inline' style={{ fontWeight: defaultPage ? 'bold' : null }}>
                      View All
                    </Typography>
                  </Box>
                </Link>
              </Grid>
            </Box>
          )}
          {viewAll && callback && (
            <Box display={{ xs: 'none', sm: 'block' }} clone py={{ md: 2, sm: 1 }} px={1}>
              <Grid item>
                <Box className='MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary text-decoration-none'>
                  <Box clone>
                    <Typography variant='button' display='inline'>
                      View All
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Box>
          )}
          {viewAllAlternative && (
            <Box display={{ xs: 'none', sm: 'block' }} clone py={{ md: 2, sm: 1 }} px={1}>
              <Grid item>
                <Box className='MuiTypography-root MuiLink-root MuiLink-underlineHover  text-decoration-none'>
                  <Box clone>
                    <Typography variant='button' display='inline' color='textSecondary'>
                      {viewAllAlternative}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Box>
          )}

          {showDivider && (
            <Box display={{ xs: 'none', sm: 'block' }} clone pb={{ sm: 2, md: 2 }} pt={{ sm: 1, md: 2 }} px={1}>
              <Grid item>
                <Divider orientation='vertical' display='inline' />
              </Grid>
            </Box>
          )}

          {linkUrl && (
            <Box display={{ xs: 'none', sm: 'block' }} clone py={{ md: 2, sm: 1 }} px={1}>
              <Grid item>
                {filters.map((_filters, index) => (
                  <Box clone={1} p={1} key={index}>
                    {_filters === 'future' ? (
                      <Link
                        key={index}
                        className='MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary text-decoration-none'
                        to={`${linkUrl}`}>
                        <Typography
                          variant='button'
                          style={{ fontWeight: _filters === filterBold ? 'bold' : null }}
                          display='inline'
                          color='primary'>
                          {_filters}
                        </Typography>
                      </Link>
                    ) : (
                      <Link
                        key={index}
                        className='MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary text-decoration-none'
                        to={`${linkUrl}/${_filters}`}>
                        <Typography
                          variant='button'
                          style={{ fontWeight: _filters === filterBold ? 'bold' : null }}
                          display='inline'
                          color='primary'>
                          {_filters}
                        </Typography>
                      </Link>
                    )}
                  </Box>
                ))}
              </Grid>
            </Box>
          )}
          {/* comment this after test */}
          {callback && (
            <Box display={{ xs: 'none', sm: 'flex' }} clone py={{ md: 2, sm: 1 }} px={1}>
              <Grid item>
                {filters.map((_filters, index) => (
                  <Box key={index} onClick={() => handleItem(_filters.code)}>
                    <Box px={1} className='cursorPointer'>
                      {_filters.name ? (
                        <Typography
                          variant='button'
                          display='inline'
                          color='primary'
                          style={{ fontWeight: _filters.code === filterBold ? 'bold' : null }}>
                          {_filters.name}
                        </Typography>
                      ) : (
                        <Typography
                          variant='button'
                          display='inline'
                          color='primary'
                          style={{ fontWeight: _filters.code === filterBold ? 'bold' : null }}>
                          {_filters}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                ))}
              </Grid>
            </Box>
          )}
          {/* TODO: Let's test it out with the simpleFilters as my condition */}
          {simpleFilters && (
            <Box display={{ xs: 'none', sm: 'block' }} clone py={{ md: 2, sm: 1 }} px={1}>
              <Grid item className='testClass'>
                {filters.map((_filter, index) => {
                  return (
                    <Box clone p={1} key={index} onClick={() => handleItem(_filter)}>
                      {/* <Box clone m={1}> */}
                      <Typography variant='button' display='inline' color='primary' className='cursorPointer'>
                        {_filter}
                      </Typography>
                      {/* </Box> */}
                    </Box>
                  );
                })}
              </Grid>
            </Box>
          )}

          <Box display={{ xs: 'block', sm: 'none' }} clone>
            <Grid item>
              <IconButton
                size='small'
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup='true'
                onClick={handleToggle}>
                <MoreVertOutlined color='primary' />
              </IconButton>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement='bottom-end'
                className='zIndex-1'
                modifiers={{
                  flip: {
                    enabled: true,
                  },
                  arrow: { enabled: true },
                }}>
                {({ TransitionProps, placement }) => (
                  <Grow {...TransitionProps}>
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown}>
                          {linkUrl &&
                            filters.map((_filters, index) => (
                              <MenuItem key={index} onClick={handleClose}>
                                <Link
                                  className='MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary text-decoration-none mw-100'
                                  to={`${linkUrl}/${_filters}`}>
                                  <Box clone px={1}>
                                    <Typography variant='button' display='inline' color='primary'>
                                      {_filters}
                                    </Typography>
                                  </Box>
                                </Link>
                              </MenuItem>
                            ))}
                          {/* Comment this if no solution comes to mind */}
                          {callback &&
                            filters.map((_filters, index) => (
                              <MenuItem key={index} onClick={handleClose}>
                                <Box
                                  onClick={() => handleItem(_filters.code)}
                                  className='MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary text-decoration-none mw-100'>
                                  <Box clone px={1}>
                                    {_filters.name ? (
                                      <Typography variant='button' display='inline' color='primary'>
                                        {_filters.name}
                                      </Typography>
                                    ) : (
                                      <Typography variant='button' display='inline' color='primary'>
                                        {_filters}
                                      </Typography>
                                    )}
                                  </Box>
                                </Box>
                              </MenuItem>
                            ))}
                          <Divider light />
                          {viewAll && linkUrl && (
                            <MenuItem onClick={handleClose}>
                              <Link
                                className='MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary text-decoration-none mw-100'
                                to={`${linkUrl}`}>
                                <Box clone px={1}>
                                  <Typography variant='button' display='inline'>
                                    View All
                                  </Typography>
                                </Box>
                              </Link>
                            </MenuItem>
                          )}
                          {viewAll && callback && (
                            <MenuItem onClick={handleClose}>
                              <Box className='MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary text-decoration-none mw-100'>
                                <Box clone px={1}>
                                  <Typography variant='button' display='inline'>
                                    View All
                                  </Typography>
                                </Box>
                              </Box>
                            </MenuItem>
                          )}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mt={3}>{children}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SectionContainerLayoutWithDate;
