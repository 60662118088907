import React, { useState, useEffect } from 'react';
import RoadShowMap from '../../components/roadshow/roadshow';
import Ecosystem from '../../data/ecosystem.json';
import * as _ from 'lodash';
import { Grid } from '@material-ui/core';
import Layout from '../../components/layout';
import SectionContainerLayoutWithDate from '../../components/section-container-layout-with-date/section-container-layout-with-date';

export default function RoadShowFuturePastPage(props) {
  const [mapLocations] = useState(_.filter(Ecosystem, { code: 'RDSH' })); // the default locations on map
  const [sections, setSections] = useState([]);
  const [popperAppear] = useState(true);
  useEffect(() => {
    const category = Ecosystem[0].data.map(({ name }) => {
      return { name };
    });
    const theSections = _.map(category, 'name');
    setSections(theSections);
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <SectionContainerLayoutWithDate title='ROADSHOWS' filters={sections} linkUrl='/connect' filterBold='past'>
        <Grid container>
          <Grid item xs={12}>
            <RoadShowMap
              page={true}
              roadShow={true}
              appear={popperAppear}
              mapLocations={mapLocations[0].data[1].data}
            />
          </Grid>
        </Grid>
      </SectionContainerLayoutWithDate>
    </Layout>
  );
}
